import React, { useEffect, useState } from "react";
import { Container, Button, Accordion, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { checkEqual, isEmpty } from "../../shared/common";
import { error } from "jquery";
import { useParams } from "react-router";
import { shopTypesApi } from "../../services/shop_api";
import Form from 'react-bootstrap/Form';
import "./typeFilter.css"
import { all } from "axios";
function TypesFilter(props) {
    const {Categorys } = useSelector((state) => state.LoginReducer);
const params = useParams()
const [filter , setfilter ] = useState({})

const [subcategory, setSubcategory] = useState(Categorys?.subcategorylist?.filter((data) => data.category === params?.symbol));
const changcat = (id , event)=>{
let array = [];
for(let i = 0;  i < Categorys?.subcategorylist.length ; i++){
if(Categorys?.subcategorylist[i].Classid === id){
    array.push(Categorys?.subcategorylist[i])
}
}
setSubcategory(array)
console.log("sghduyagszzzduy" , array)
}
const [ collectionSymbol , SetCollectionSymbol ] = useState(null)

useEffect(() => {
    if(!checkEqual(collectionSymbol,  props?.collectionType.CollectionSymbol)){
        SetCollectionSymbol(props?.collectionType.CollectionSymbol)
    }
},[props?.collectionType.CollectionSymbol])

const [tag , setTag] = useState('USD')
const pricerangeinit = {
    type : tag , 
    min : Math.min(), 
    max : Math.max()
}
const [pricerange, setpricerange] = useState(pricerangeinit)
const range = (e) => {
    const {id , value} = e.target
    setpricerange({...pricerange , [id] : value})
    seterror({})
}
const [errossr , seterror] =useState({}) 
const error = {}
function pricefilter(){

if(Number(pricerange?.min) >= Number(pricerange?.max) ){
    error.price = "min should be less than max"
    seterror(error)
    return 
} 
console.log("pricerangeerror" , pricerange ,error )

props.explore({ pricerange : pricerange , status : true})
}

let regex = /^[0-9]+$/;

const [alltypes , SetAllTypes ] = useState([])
const [types , SetTypes ] = useState([])

useEffect(() => {
        
    const fetchTypes = async () => {
        const data  = await shopTypesApi()
        console.log("dasxxx" , data.message )
        SetAllTypes(data.message)
    }

    fetchTypes();


},[])


useEffect(() => {},

[    ])

    return (
        <div>


            <Accordion defaultActiveKey="01">
                <Accordion.Item eventKey="01">
                    <Accordion.Header><h4 className="heading mb-0">Price</h4></Accordion.Header>
                    <Accordion.Body>
                        <Dropdown>
                            <DropdownToggle className="pricefilter">{tag}</DropdownToggle>
                            {/* <DropdownMenu>
                                <DropdownItem onClick={()=> setTag('BNB')}>BNB</DropdownItem>
                                <DropdownItem onClick={()=> setTag('MATIC')} >MATIC</DropdownItem>
                            </DropdownMenu> */}
                        </Dropdown>
                        <div className="d-flex align-items-center pt-4">
                            <input type="Number" className="form-control profinput" placeholder="Min" id ="min" 
                            onChange={range} />
                            <h5 className="whtsclr mx-3 mb-0">to</h5>
                            <input type="Number" className="form-control profinput" placeholder="Max" id ="max" onChange={range} />
                        </div>
                        { errossr.price &&  <p className="text-danger">{errossr.price}</p>}
                        <div className="text-center mt-4 mb-3"><Button className="explorefull" onClick={() => {
pricefilter()
                           }}>APPLY</Button></div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <h4 className="heading ps-2 mb-0">Types</h4>
            



            {/* <Accordion defaultActiveKey={1}>

                {(!isEmpty(subcategory)) && subcategory?.map((e, i) =>
                    <Accordion.Item eventKey={i + 2}>
                        <Accordion.Header onClick={() => props.toggleAccordion(i + 2)}><h4 className="whtsclr fw-300 mb-0">{e.key}</h4> <p className="grayclr posl">{e.value.length ? e.value.length  : 0 }</p></Accordion.Header>
                        <Accordion.Body>
                            <input type="search" className="form-control profinput mb-2" placeholder="Search" onChange={(event) => {
                                console.log(e , event.target.value)
                                const filteredData = {
                                    ...e,
                                    [e.key]: e.value.filter(item => item.value.includes(event.target.value))
                                  };
                                  console.log("filteredData",filteredData , filter[e.key])
                                  e = filteredData
                                  setfilter(filteredData)
                            }} />
                            { (!isEmpty(filter[e.key])) ? filter[e.key]?.map((val, i) =>
                                <div className="d-flex align-items-center justify-content-between pt-3">
                                    <div class="form-check">
                                        {console.log("dshdsgzd" , e)}
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(event) => props.handlechange( e.Classid , { [e?.key] : val?.value }  ,event) } checked={val.checked} />
                                        <label class="form-check-label whtsclr fw-200 f-20 ms-2" for="flexCheckDefault">
                                            {val.value}
                                        </label>
                                    </div>
                                </div>
                            ) : e.value.map((val, i) =>
                            <div className="d-flex align-items-center justify-content-between pt-3">
                                <div class="form-check">
                                    {console.log("dshdsgzd" , e)}
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(event) => props.handlechange( e.Classid , { [e?.key] : val?.value }  ,event) } checked={val.checked} />
                                    <label class="form-check-label whtsclr fw-200 f-20 ms-2" for="flexCheckDefault">
                                        {val.value}
                                    </label>
                                </div>
                            </div>
                        )
                        
                        
                        }
                        </Accordion.Body>
                    </Accordion.Item>
                )}
            </Accordion> */}



<Form>
        {/* <div key={`all`} className="mb-3 checkbox">
        <Form.Check // prettier-ignore
            type={"checkbox"}
            id={`all`}
            label={`All`}
          />
        </div> */}

      {[...types].map((type) => (
        <div key={`default-${type}`} className="mb-3 checkbox">
          <Form.Check // prettier-ignore
            type={"checkbox"}
            idt={type}
            label={`default ${type}`}
          />


        </div>
      ))}
    </Form>

        </div>
    )
}

export default TypesFilter
import { apitrigger, axiosFunc } from "../shared/common";
import config from '../config'
import { Decryptdata, Encryptdata } from "../shared/screatkeeper";



 
export const shopTypesApi = async () => {
  
    const senddata = {
        method: 'get',
        url: `${config.BACK_URL}/shop/types`,
    }
    let Resp = await axiosFunc(senddata)

    return Resp.data
}